export function resumeAudioOnClick(context: AudioContext): void {
  const resume = async () => {
    if (context.state === 'suspended') {
      await context.resume()
    }

    document.removeEventListener('click', resume)
    document.removeEventListener('touchstart', resume)
    document.removeEventListener('touchend', resume)
  }

  document.addEventListener('click', resume)
  document.addEventListener('touchstart', resume)
  document.addEventListener('touchend', resume)
}
