export class AudioLoader {
  context: AudioContext

  constructor(context: AudioContext) {
    this.context = context
  }

  async loadBuffer(url: URL | string): Promise<AudioBuffer>
  async loadBuffer(url: (URL | string)[]): Promise<AudioBuffer>
  async loadBuffer(url: URL | string | (URL | string)[]): Promise<AudioBuffer> {
    const urls = Array.isArray(url) ? url : [url]

    for (const url of urls) {
      try {
        const response = await fetch(url)
        const arrayBuffer = await response.arrayBuffer()
        const buffer = await this.context.decodeAudioData(arrayBuffer)

        return buffer
      } catch (error) {
        console.log(`Failed to load ${url}`)
        console.error(error)
      }
    }

    throw new Error('Could not load audio from any of the provided URLs')
  }

  async loadNode(url: URL | string): Promise<AudioBufferSourceNode>
  async loadNode(urls: (URL | string)[]): Promise<AudioBufferSourceNode>
  async loadNode(
    url: URL | string | (URL | string)[]
  ): Promise<AudioBufferSourceNode> {
    const buffer = await this.loadBuffer(url as URL)

    const node = this.context.createBufferSource()

    node.buffer = buffer
    node.connect(this.context.destination)

    return node
  }
}
