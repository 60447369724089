export class AudioTracker {
  #node: AudioBufferSourceNode
  #startedAt = 0
  #stoppedAt = 0
  #playing = false

  constructor(node: AudioBufferSourceNode) {
    this.#node = node

    node.addEventListener('ended', () => {
      this.#startedAt = 0
      this.#stoppedAt = 0
      this.#playing = false
    })
  }

  get node(): AudioBufferSourceNode {
    return this.#node
  }

  get playing(): boolean {
    return this.#playing
  }

  play(): void {
    const offset = this.#stoppedAt

    this.#node.start(0, offset)
    this.#startedAt = this.#node.context.currentTime - offset
    this.#stoppedAt = 0
    this.#playing = true
  }

  pause(): void {
    this.#node.stop(0)
    this.#startedAt = 0
    this.#stoppedAt = this.#node.context.currentTime - this.#startedAt
    this.#playing = false
  }

  stop(): void {
    this.#node.stop(0)
    this.#startedAt = 0
    this.#stoppedAt = 0
    this.#playing = false
  }

  get currentTime(): number {
    if (this.#stoppedAt) {
      return this.#stoppedAt
    }

    if (this.#startedAt) {
      const time = this.#node.context.currentTime - this.#startedAt

      if (this.#node.loop) {
        const duration = this.#node.buffer?.duration

        if (duration) {
          this.#startedAt =
            this.#node.context.currentTime - (time % duration || 0)

          return time % duration
        }
      }

      return time
    }

    return 0
  }
}
